<template>
  <div>
    <div class="lianxi">
      <div class="top">
        <img src="../../common/image/组 24.png" alt="" />
      </div>
      <!-- 联系我们 -->
      <div class="contact">
        <div class="main">
          <h3 class="title">联系我们</h3>
          <div class="provide">
            <div class="business">
              <h4>/供应链业务</h4>
              <p>
                如果您是供应链厂商，想要深入市场寻求新的机会；如果你一腔热血，想加入我们一起开拓新的事业！<u
                  >请点此了解》</u
                >
              </p>
            </div>
            <div class="business">
              <h4>/线上商家业务</h4>
              <p>
                如果您想借助循循尚购平台销售您的商品，了解循循尚购平台政策，<u
                  >请点此了解》</u
                >
              </p>
            </div>
            <div class="business">
              <h4>/线下商家业务</h4>
              <p>
                如果您是您是线下商家门店，想要拓客引流，<u>请点此我想入驻》</u>
              </p>
            </div>
            <div class="business">
              <h4>/招聘职位</h4>
              <p>
                如果您希望加入循循尚购企业，与循循尚购企业共同成长与发展，<u
                  @click="$router.push('/recruit')"
                  style="color: #006bff; cursor: pointer"
                  >欢迎查看招聘职位》</u
                >
              </p>
            </div>
            <div class="business">
              <h4>/联系方式</h4>
              <div class="flexsb">
                <p>联系电话：0755-23017400 0577-23017402</p>
                <p>邮箱：hqsm@hqsmtech.com</p>
                <p>地址：深圳市宝安区银田创意园宗泰数字产业园B11栋</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 地图 -->
        <div class="mapdata">
          <baidu-map
            class="map"
            :center="{ lng: mapArr.lng, lat: mapArr.lat }"
            :zoom="15"
            :scroll-wheel-zoom="true"
          >
            <!-- 类型切换 -->
            <bm-map-type
              :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
              anchor="BMAP_ANCHOR_TOP_RIGHT"
            ></bm-map-type>
            <!-- marker坐标 -->
            <bm-marker
              :position="{ lng: mapArr.lng, lat: mapArr.lat }"
              :dragging="true"
              animation="BMAP_ANIMATION_BOUNCE"
            >
            </bm-marker>
            <!-- 缩放比例 -->
            <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
            <!-- 缩放控件 -->
            <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          </baidu-map>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "lianxi",
  props: ["datalist"],
  data() {
    return {
      mapArr: {
        lat: 22.592842,
        lng: 113.866312,
      },
    };
  },
  create() { },
  mounted() {
  },
  wacth: {},
  methods: {

  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.lianxi {
  .top {
    img {
      width: 100%;
    }
  }
  .contact {
    background: #fafafa;
    .main {
      margin: auto;
      position: relative;
      top: -40px;
      width: 1200px;
      background-color: #fff;
      min-height: 500px;
      padding: 0 60px;
      padding-top: 34px;
      box-sizing: border-box;
      .title {
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 51px;
      }
      .provide {
        .business {
          margin-bottom: 20px;
          h4 {
            margin-bottom: 8px;
          }
          p {
            color: #333;
            font-size: 14px;
          }
        }
      }
    }
    .mapdata {
      position: relative;
      top: -40px;
      width: 1200px;
      min-height: 749px;
      margin: auto;
      background: #ffffff;
      padding-bottom: 50px;
      .map {
        width: 1075px;
        height: 749px;
        // background: pink;
        margin: auto;
      }
    }
  }
}
</style>
